/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Buffer} from 'buffer';
import {useEffect, useRef, useState, useContext} from 'react';
import { ConfigContext } from '../context/config';

const backendURL = 'http://localhost:3001/api/text-to-speech'

/**
 * Response return by the synthesize method.
 */
export interface SynthesizeResponse {
  /**
   * Encoded audio bytes.
   */
  audioContent: ArrayBuffer;
}

interface AudioProcessCallback {
  (e: Float32Array): void;
}

const useTextToSpeech = () => {
  const audioContext = useRef(new AudioContext());
  const processor = useRef(audioContext.current.createScriptProcessor(1024, 1, 1));
  const dest = useRef(audioContext.current.createMediaStreamDestination());
  const delayNode = useRef(audioContext.current.createDelay(170));
  const source = useRef(audioContext.current.createBufferSource());
  const onProcessCallback = useRef<AudioProcessCallback>((e) => {});

  useEffect(() => {
    source.current.connect(processor.current);
    processor.current.connect(dest.current);
    source.current.connect(delayNode.current);
    delayNode.current.delayTime.value =
      Number(new URL(window.location.href).searchParams.get('audio_delay') ?? '300') / 1000;
    delayNode.current.connect(audioContext.current.destination);
    source.current.start();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (source.current) {
        source.current.stop();
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      audioContext.current.suspend().then(() => {
        console.log('audioContext suspended');
      });
    } else {
      audioContext.current.resume().then(() => {
        console.log('audioContext resumed');
      });
    }
  };

  const setOnProcessCallback = (callback: AudioProcessCallback) => {
    onProcessCallback.current = callback;
  };

  const synthesize = async (text: string) => {
      try {
          const response = await fetch(backendURL, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ text })
          });

          if (!response.ok) {
              throw new Error(`Failed to generate speech with status: ${response.status}`);
          }

          const audioContent = await response.arrayBuffer();
          return audioContent;  // This now holds the audio data as ArrayBuffer
      } catch (error) {
          console.error('Error fetching transcription:', error);
          throw error;
      }
  };

  const play = async (audioContent: ArrayBuffer) => {
      try {
          const audioBuffer = await audioContext.current.decodeAudioData(audioContent);
          if (source.current) {
              source.current.stop();
              source.current.disconnect();
          }
          source.current = audioContext.current.createBufferSource();
          source.current.buffer = audioBuffer;

          // Ensure the source is connected to the processor and also directly to the destination.
          source.current.connect(processor.current);
          processor.current.connect(audioContext.current.destination); // Ensure processor routes to destination
          source.current.connect(audioContext.current.destination); // Also connect source directly to destination

          // Set up the audio process event for animations
          processor.current.onaudioprocess = (e) => {
              const audioData = e.inputBuffer.getChannelData(0);
              onProcessCallback.current(audioData);  // Trigger the callback that can be linked to your animation
          };

          source.current.start();
          source.current.onended = () => {
              console.log('Playback finished');
          };
      } catch (error) {
          console.error('Error playing audio:', error);
      }
  };


  const convert = async (text: string) => {
      if (!text) {
          return;
      }

      try {
          const audioContent = await synthesize(text);
          await play(audioContent);
      } catch (error) {
          console.error('Error processing text-to-speech:', error);
      }
  };


  return {
    convert,
    setOnProcessCallback,
  };
};

export default useTextToSpeech;

