/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

/** Main Layout Props */
export interface MainLayoutProps {
  children: React.ReactNode;
}

/** Layout component. */
export function Layout(props : MainLayoutProps) {
  return (
    <div 
      style={{ 
        height: "100vh", 
        width: "100%",
        overflow: "hidden"
      }}
    >  
      {props.children}
    </div>
  );
}